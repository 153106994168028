html, body, app-root {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

body {
    overflow: hidden;
    margin: 0;

    * {
      caret-color: transparent;
    }

    input[type="text"],
    input[type="email"],
    input[type="password"],
    input[type="number"],
    input[type="search"],
    input[type="tel"],
    input[type="url"],
    textarea {
      caret-color: auto;
    }

    // Turn off text selection
    //-webkit-user-select: none;
    //-khtml-user-select: none;
    //-moz-user-select: none;
    //-o-user-select: none;
    //user-select: none;
}
